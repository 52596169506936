import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import {
	Button,
	TextField,
	MuiThemeProvider,
  FormControlLabel,
  FormHelperText,
	createMuiTheme,
  Checkbox
} from '@material-ui/core'
import Select from 'react-select'
import { useHistory, useParams } from 'react-router-dom'
import { deleteVideo, getVideoById, postVideo, updateVideo } from '../../../../api/video'
import { useSkeleton } from '../../../hooks/useSkeleton'
import { alertError, alertSuccess } from '../../../../utils/logger'
import PreviewDialog from "../../../components/dialogs/PreviewDialog"
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock'
import { getTags } from '../../../../api/tag'
import { SERVER_URL } from "../../../../utils"
import Editor from '../../../components/editor/Editor'
import replaceSpecialCharacters from 'replace-special-characters'

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60'
		}
  }
})

function getEmptyVideo() {
  return {
    fullName: {},
		summary: {},
		quote: {},
		content: {},
		image: '',
		imagevert: '',
		idyoutube: {},
		duration: {},
    tags: [],
		transmin: {},
		transtext: {},
		active: true,
    featured: false,
		seotitle: {},
		seodescription: {},
		seoURL: {},
		transcriptions: []
  }
}

const getDataSelect = (elements) => {
  const data = []
  for (const element of elements) {
    let elem = {}
    elem.label = element.fullName.es
    elem.value = element._id
    data.push(elem)
  }
  return data
}

export default function EditVideosPage() {
  const [video, setVideo] = useState(getEmptyVideo())
  const [newTranscription, setNewTranscription] = useState({minute: '', text: ''})
	const [selectedImage, setSelectedImage] = useState(null)
	const [selectedImagevert, setSelectedImagevert] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
	const [openPreviewImage, setOpenPreviewImage] = useState(false)
	const [openPreviewImagevert, setOpenPreviewImagevert] = useState(false)
	const [tags, setTags] = useState(null)
  const videoId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

	//console.log('tags', tags)
  useEffect(() => {
		getTags().then(resp => {
			if (resp.status === 200) {
				setTags(getDataSelect(resp.data))
			}
		}).catch(error => {
			alertError({ error: error, customMessage: 'Could not get tags.' })
			history.push('/videos')
		})
    if (!videoId) {
      disableLoadingData()
      return
    }
    getVideoById(videoId).then(res => {
      if (res.status === 200) {
        setVideo(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get video.' })
      history.push('/videos')
    })
  }, [videoId, disableLoadingData, history])

  function saveVideo() {
		//console.log('video to save / update', video)
    if (!videoId) {
      postVideo(video, selectedImage, selectedImagevert).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Saved!', customMessage: 'Video successfully created.' })
          history.push('/videos')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save video.' })
      })
    } else {
      updateVideo(videoId, video, selectedImage, selectedImagevert).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Saved!', customMessage: 'Changes successfully saved.' })
          history.push('/videos')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save changes.' })
      })
    }
  }

  const handleChange = (element, lang) => (event) => {
    if (lang) {
      if (event.target.value === ' ') return
      if (!video[element]) video[element] = {}
      let newText = video[element]
      newText[lang] = event.target.value
      setVideo({ ...video, [element]: newText })
    } else setVideo({ ...video, [element]: event.target.value })
  }

  const handleChangeSpecialChars = (element, lang) => (event) => {
    if (lang) {
      if (event.target.value === ' ') return
      if (!video[element]) video[element] = {}
      let newText = video[element]
      newText[lang] = replaceSpecialCharacters(event.target.value)
      setVideo({ ...video, [element]: newText })
    } else setVideo({ ...video, [element]: replaceSpecialCharacters(event.target.value) })
  }

	const handleChangeTags = new_selected => {
		setVideo(prevVideo => ({
			...prevVideo,
			tags: new_selected.map(elem => elem.value) || []
		}))
	}

  const handleAddTranscription = lang => () => {
    setVideo(prevVideo => {
      let _transcriptions = prevVideo.transcriptions
				?	{...prevVideo.transcriptions }
				: {}
			if (!_transcriptions[lang]) _transcriptions[lang] = []
      _transcriptions[lang].push(newTranscription)
			//console.log('_transcriptions to save', _transcriptions)
      return {
        ...prevVideo,
        transcriptions: _transcriptions
      }
    })
    setNewTranscription({minute: '', text: ''})
  }

	const handleEditTranscription = (lang, index) => editedTranscription => {
		setVideo(prevVideo => {
			let _transcriptions = prevVideo.transcriptions
			? {...prevVideo.transcriptions}
			: {}

			if(!_transcriptions[lang]) _transcriptions[lang] = []
			if (!_transcriptions[lang][index]) return

			_transcriptions[lang][index] = editedTranscription

			return {
				...prevVideo,
				transcriptions: _transcriptions
			}

		})
	}

  const handleDeleteTranscription = lang => index => {
    setVideo(prevVideo => {
      let _transcriptions = prevVideo.transcriptions
			?	{...prevVideo.transcriptions }
			: {}
			if (!_transcriptions[lang]) _transcriptions[lang] = []
      _transcriptions[lang].splice(index, 1)
      return {
        ...prevVideo,
        transcriptions: _transcriptions
      }
    })
  }

  const renderMultilanguageTabContent = lang => {
    return (
      <>
        <TextField
          id={`fullName`}
          label="Full name (internal name)"
          value={(video.fullName && video.fullName[lang]) || ''}
          onChange={handleChange('fullName', lang)}
          InputLabelProps={{
            shrink: true
          }}
          margin="normal"
          variant="outlined"
          required
        />
        <TextField
          id={`duration`}
          label="Total duration (mm:ss)"
          value={(video.duration && video.duration[lang]) || ''}
          onChange={handleChange('duration', lang)}
          InputLabelProps={{
            shrink: true
          }}
          margin="normal"
          variant="outlined"
          required
        />
				<TextField
					id={`summary`}
					label="Summary"
					value={(video.summary && video.summary[lang]) || ''}
					onChange={handleChange('summary', lang)}
					multiline={true}
					rows={4}
					InputLabelProps={{
						shrink: true
					}}
					margin="normal"
					variant="outlined"
					required
				/>
        <TextField
          id={`quote`}
          label="Quote"
          value={(video.quote && video.quote[lang]) || ''}
          onChange={handleChange('quote', lang)}
          InputLabelProps={{
            shrink: true
          }}
          margin="normal"
          variant="outlined"
        />
				<Editor
				body={(video.content && video.content[lang]) || ''}
				setBody={_value => handleChange('content', lang)({target: {value: _value}})}
				className='max-height'
				/>
        <TextField
          id={`idyoutube`}
          label="ID Youtube"
          value={(video.idyoutube && video.idyoutube[lang]) || ''}
          onChange={handleChange('idyoutube', lang)}
          InputLabelProps={{
            shrink: true
          }}
          margin="normal"
          variant="outlined"
        />
			<FormHelperText>Copiar el código que aparece en la página de Youtube de cada vídeo</FormHelperText>
			<label className='mt-4' htmlFor={"upload-image"}>
					<input
						style={{ display: 'none' }}
						id={"upload-image"}
						name={"upload-image"}
						type="file"
						onChange={(e) => {
							setSelectedImage(e.target.files[0])
						}}
					/>
					<Button
						style={{ marginRight: '15px' }}
						color="secondary"
						component="span"
						variant="outlined">
						{((selectedImage || video.image !== '') ? 'Change image' : 'Upload image')}
					</Button>
				</label>
				{(selectedImage || video.image !== '') &&
					<>
						<Button
							onClick={() => setOpenPreviewImage(true)}
							style={{ marginRight: '15px' }}
							variant="outlined">
							{'Preview image'}
						</Button>
						<PreviewDialog
							title={'Preview image'}
							open={openPreviewImage}
							setOpen={setOpenPreviewImage}
							src={(selectedImage) ? URL.createObjectURL(selectedImage) : `${SERVER_URL}/${video.image}`}
						/>
					</>
			}<br/>
					<FormHelperText>(proportional dimensions 1280 x 750)</FormHelperText>
				<label className='mt-4' htmlFor={"upload-imagevert"}>
					<input
						style={{ display: 'none' }}
						id={"upload-imagevert"}
						name={"upload-imagevert"}
						type="file"
						onChange={(e) => {
							setSelectedImagevert(e.target.files[0])
						}}
					/>
					<Button
						style={{ marginRight: '15px' }}
						color="secondary"
						component="span"
						variant="outlined">
						{((selectedImagevert || video.imagevert !== '') ? 'Change image vertical' : 'Upload image vertical')}
					</Button>
				</label>
				{(selectedImagevert || video.imagevert !== '') &&
					<>
						<Button
							onClick={() => setOpenPreviewImagevert(true)}
							style={{ marginRight: '15px' }}
							variant="outlined">
							{'Preview image vertical'}
						</Button>
						<PreviewDialog
							title={'Preview image'}
							open={openPreviewImagevert}
							setOpen={setOpenPreviewImagevert}
							src={(selectedImagevert) ? URL.createObjectURL(selectedImagevert) : `${SERVER_URL}/${video.imagevert}`}
						/>
					</>
			}<br/>
					<FormHelperText>(proportional dimensions 770 x 750)</FormHelperText>
				<Select
					options={tags}
					value={tags?.filter(tag => video.tags?.includes(tag.value))}
					onChange={handleChangeTags}
					isClearable
					isMulti
					isSearchable
					placeholder={`Select tags...`}
					className='mb-3 mt-3'
				/>
			<h5 className='mt-4'>Video transcription</h5>
				{video?.transcriptions && video?.transcriptions[lang] &&
				video.transcriptions[lang]?.map((elem, index) => <div>
					<TextField
						className='col-1'
						id={`transmin`}
						label="Minute"
						value={elem.minute || ''}
            // disabled={true}
						onChange={e => handleEditTranscription(lang, index)({minute: e.target.value, text: elem.text})}
						InputLabelProps={{
							shrink: true,
              // readOnly: true
						}}
						margin="normal"
						variant="outlined"
					/>
					<TextField
						className='col-10 pl-1'
						id={`transtext`}
						label="Text"
						value={elem.text || ''}
            // disabled={true}
						onChange={e => handleEditTranscription(lang, index)({text: e.target.value, minute: elem.minute})}						InputLabelProps={{
							shrink: true,
              // readOnly: true
						}}
						multiline={true}
						rows={3}
						margin="normal"
						variant="outlined"
					/>
		      <Button
          onClick={() => handleDeleteTranscription(lang)(index)}
						className='col-1 pl-1 mt-5'
		        variant="outlined">
		        Delete
		      </Button>
				</div>)}
        <TextField
						className='col-1'
						id={`transmin`}
						label="Minute"
						value={newTranscription.minute || ''}
						onChange={event => setNewTranscription({...newTranscription, minute: event.target.value})}
						InputLabelProps={{
							shrink: true
						}}
						margin="normal"
						variant="outlined"
					/>
					<TextField
						className='col-10 pl-1'
						id={`transtext`}
						label="Text"
						value={newTranscription.text || ''}
						onChange={event => setNewTranscription({...newTranscription, text: event.target.value})}
						InputLabelProps={{
							shrink: true
						}}
						multiline={true}
						rows={3}
						margin="normal"
						variant="outlined"
					/>
		      <Button
          onClick={handleAddTranscription(lang)}
						className='col-1 pl-1 mt-5'
		        variant="outlined">
		        Add
		      </Button>
				<hr/>
				<h5>SEO information</h5>
        <TextField
          id={`seotitle`}
          label="Title (60 chars aprox.)"
          value={(video.seotitle && video.seotitle[lang]) || ''}
          onChange={handleChange('seotitle', lang)}
          InputLabelProps={{
            shrink: true
          }}
          margin="normal"
          variant="outlined"
        />
				<TextField
					id={`seodescription`}
					label="Description (160 chars aprox.)"
					value={(video.seodescription && video.seodescription[lang]) || ''}
					onChange={handleChange('seodescription', lang)}
					multiline={true}
					rows={2}
					InputLabelProps={{
						shrink: true
					}}
					margin="normal"
					variant="outlined"
				/>
				<TextField
          id={`seoURL`}
          label="URL"
          value={(video?.seoURL && video?.seoURL[lang]) || ''}
          onChange={handleChangeSpecialChars('seoURL', lang)}
          InputLabelProps={{
            shrink: true
          }}
          margin="normal"
          variant="outlined"
          required
        />
				<FormControlLabel
					control={<Checkbox checked={video.featured} onChange={() => setVideo({ ...video, featured: !video.featured })} name="checkFeatured" />}
					label="Featured"
				/>
				<FormControlLabel
					control={<Checkbox checked={video.active} onChange={() => setVideo({ ...video, active: !video.active })} name="checkActive" />}
					label="Active"
				/>
      </>
    )
  }

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Edit video'>
        </CardHeader>
        <CardBody>
          <MultilanguageTabBlock
						multilanguageTabContent = {renderMultilanguageTabContent}
					/>
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/videos')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <Button
        onClick={() => saveVideo()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Save video
      </Button>
      {videoId && <>
        <MuiThemeProvider theme={theme}>
          <Button
            onClick={() => setOpenConfirmDialog(true)}
            variant="outlined"
            color="secondary">
            Delete video
          </Button>
        </MuiThemeProvider>

        <ConfirmDialog
          title={'Are you sure you want to delete this video?'}
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={() => {
            deleteVideo(videoId).then(res => {
              if (res.status === 204) {
                alertSuccess({ title: 'Deleted!', customMessage: 'Video deleted successfully' })
                history.push('/videos')
              }
            }).catch(error => {
              alertError({ error: error, customMessage: 'Could not delete economic sector.' })
            })
          }}
        />
      </>}
    </>
  );
}
